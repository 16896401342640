'use client'

import { TocProvider, useToc } from "@/contexts/TocProvider";
import classNames from "classnames";
import { usePathname, useRouter } from "next/navigation"
import React, { ReactNode, useEffect, useId, useRef, useState } from "react"
import { ResponsiveMenu } from "./ResponsiveMenu.client";

interface heading {
    text: string
    id: string
    level: number
}

interface TocSidebarProps {
  children?: ReactNode
  main: ReactNode
  className?: string
  mainClassName?: string
}

export const TocSidebar = ({children, ...props}: TocSidebarProps) =>  {
  return <TocProvider>
    <TocSidebarInternal {...props}>{children}</TocSidebarInternal>
  </TocProvider>
}

const TocSidebarInternal = ({className, children, main, mainClassName}: TocSidebarProps) =>  {
    const [headings, setHeadings] = useState([] as heading[])
    const [activeId, setActiveId] = useState('')
    const [mutationCounter, setMutationCounter] = useState(0)
    const pathname = usePathname()
    const router = useRouter()
    const ref = useRef<HTMLDivElement>(null)
    const {enabled} = useToc()
    
    // Set the heading based on screen scroll position.
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          const actives = entries.filter((e) => e?.isIntersecting);
          if (actives.length) {
              setActiveId(actives[0].target.id)
          }
        }, {
          root: null,
          rootMargin: "0% 20% -95% 0%",
          threshold: 0.95
        })
          
        const elements = ref.current?.querySelectorAll("h2[id], h3[id]")

        elements?.forEach((elem) => observer.observe(elem))

        setHeadings(Array.from(elements ?? []).map((elem) => ({
          id: (elem as HTMLElement).id,
          text: (elem as HTMLElement).innerText.replace('¶', ''),
          level: Number(elem.nodeName.charAt(1))
        })))

        return () => {
          setHeadings([])
          observer.disconnect()
        }
    }, [pathname, ref, mutationCounter])

    // Watch for DOM changes to rebuild ToC when it occurs.
    useEffect(() => {
        if (ref.current) {
            const mutationObserver = new MutationObserver(() => setMutationCounter((prev) => prev + 1))
            mutationObserver.observe(ref.current, {childList: true, subtree: true})
            return () => mutationObserver.disconnect()
        }
    }, [mutationCounter])

    return (
      <>
       {(children || (enabled && headings.length > 0)) && (
         <ResponsiveMenu 
         orientation="right" 
         className={classNames(className, "print:hidden order-last group-data-[draw=closed]/body:lg:flex-shrink xl:flex-shrink group-data-[draw=closed]/body:lg:border-s xl:border-s group-data-[draw=closed]/body:lg:border-gray-600 xl:border-gray-600 group-data-[draw=closed]/body:lg:ps-6 xl:ps-6")}
         expandedClass="group-data-[draw=closed]/body:lg:basis-1/4 xl:basis-1/4 group-data-[draw=closed]/body:lg:shrink-0 xl:shrink-0"
         panelClass="group-data-[draw=closed]/body:lg:sticky xl:sticky group-data-[draw=closed]/body:lg:top-0 xl:top-0 group-data-[draw=closed]/body:lg:overflow-y-auto xl:overflow-y-auto"
         openLabel="Open sidebar"
         closeLabel="Close sidebar"
         >
            { enabled && headings.length > 0 && (      
              <nav className='toc mb-4 relative'>
                  <h4 className="pl-2 sticky top-0 bg-gray-300 font-semibold text-lg border-b border-gray-600 py-2 mb-2">On this page</h4>
                  <ul className="flex flex-col gap-2" role="menu">
                      {headings.map(heading => (
                        <li key={heading.id} role="none">
                          <a 
                          role="menuitem"
                          href={`#${heading.id}`}
                          className={classNames(
                            getClassName(heading.level),
                            (activeId === heading.id) ? "bg-gray-500" : "hover:border-navy-400 hover:bg-gray-500", 
                            "transition-colors duration-300 py-1 block rounded",
                          )}
                          onClick={(e) => {
                            e.preventDefault()
                            const selector = `#${heading.id}`
                            document.querySelector(selector)?.scrollIntoView({
                              behavior: "smooth"
                            })
                            router.push(selector, {scroll: false})
                          }}>{heading.text}</a>
                        </li>
                      ))}
                  </ul>
              </nav>
            )}
            {children}
          </ResponsiveMenu>
        )}
        <div ref={ref} className={classNames(mainClassName, (enabled || children) ? 'basis-1/2 flex-grow group-data-[draw=closed]/body:lg:shrink xl:shrink group-data-[draw=closed]/body:lg:overflow-auto xl:overflow-auto' : 'basis-3/4')}>
            {main}
        </div>
      </>
    );
}

const getClassName = (level:number) => {
    switch (level) {
      case 2:
        return 'pl-2'
      case 3:
        return 'pl-6'
      case 4:
        return 'head4'
      default:
        return ''
    }
  }