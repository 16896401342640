'use client'
import { GetDrupalPageFromPathResponse, NoDrupalPageInPath } from "@/lib/drupal/drupal.server";
import { DrupalNode, DrupalNodeModeratedRevision } from "@/types";
import { createContext, ReactNode, useContext } from "react";
/**
 * @file
 * RedirectProvider context provides
 * redirects to the frontend so they can be issued
 * with query hashes maintained on them.
 * 
 * @see app/[...slug]/Page
 * @see components/app/drupal/PageContent
 */

interface ContentRouteInterface<T extends DrupalNode> extends GetDrupalPageFromPathResponse<T> {
  type: T['type']
}

interface NoContentRouteInterface extends NoDrupalPageInPath {
  type?: undefined
}

const ContentRouteContext = createContext<ContentRouteInterface<DrupalNode>|NoContentRouteInterface>({})

export function useContentFromRoute<T extends DrupalNode>() {
  return useContext(ContentRouteContext) as ContentRouteInterface<T> | NoContentRouteInterface
}

export const ContentRouteProvider = <T extends DrupalNode>(
  {children, ...context}: (ContentRouteInterface<T>|NoContentRouteInterface) & {
    children: ReactNode,
  }) => {
  return <ContentRouteContext.Provider value={context}>
    {children}
  </ContentRouteContext.Provider>
}