'use client'
import React, { Fragment, use } from 'react';
import LogoSVG from '@/public/assets/logo.svg';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { DrupalMenuLinkContent } from 'next-drupal';
import Link from 'next/link';
import classNames from 'classnames';
import { Session } from 'next-auth';
import { UserNav } from './UserNav.client';
import { useProducts } from '@/contexts/ProductsProvider';
import { usePathname } from 'next/navigation';
import useScreens from '../hooks/useScreens';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { LoginButton } from '../wysiwyg/LoginButton.client';
import { Bars3Icon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useFeatureFlags } from '@/contexts/FeatureFlagsProvider';
import { useUserInformation } from '@/contexts/UserProvider';
import { useSidebarDraw } from '@/contexts/SidebarDrawProvider';
import { useSearch } from '@/contexts/SearchProvider';

export const Header = ({ menu, session }: {
  menu: Promise<{
    tree: DrupalMenuLinkContent[]
  }>,
  session: Session | null
}) => {
  const { tree: menuLinks } = use(menu)
  const screen = useScreens()
  const { draw } = useSidebarDraw()

  const defaultOpen = screen === undefined || screen.xl

  return <header className='bg-white text-navy-600'>
    <Disclosure as="nav" aria-label="Site" className={
      "container mx-auto py-3 px-5 flex flex-col xl:flex-row gap-x-10"
     } defaultOpen={false}>
      {({open}) => (
        <>
          <div className='flex flex-row gap-2'>
            <Link className="flex place-items-baseline" href="/">
                <LogoSVG className='me-1 h-6' />
                <span className='uppercase text-[30px] font-thin'>Docs</span>
            </Link>
            <div className='flex-grow xl:hidden'>&nbsp;</div>
            <div className='flex gap-x-1 xl:hidden'>
              <ChatButton />
              <SearchButton />
              <UserNav session={session} />
              <LoginButton />
            </div> 
            <Disclosure.Button
              className={classNames(
                "flex items-center gap-2 print:hidden",
                "xl:top-1 xl:absolute xl:hidden",
                "bg-gray-400 rounded-md p-2 hover:bg-navy-600 hover:text-white",
                "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
              )}>
              {
                open ? 
                <XMarkIcon className="block h-6 w-6" aria-hidden="true"  />
                :
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              }
            </Disclosure.Button>
          </div>
          <Transition
          show={open || defaultOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className={classNames(
              "flex flex-col basis-full",
              "xl:gap-8 xl:flex-row xl:basis-auto",
              defaultOpen ? 'items-center' : '',
            )} 
            static={defaultOpen}
            >
              {menuLinks.map(link => link.items 
                ? <DropDownMenu key={link.id} label={link.title} items={link.items} href={link.url} />
                : <Link key={link.id} href={link.url}>{link.title}</Link>
              )}
            </Disclosure.Panel>
          </Transition>
          <div className='flex-grow' />
          <div className='gap-x-1 hidden xl:flex'>
            <ChatButton />
            <SearchButton />
            { draw === null && (
              <>
                <UserNav session={session} />
                <LoginButton />
              </>
            )}
          </div>  
        </>
      )}
    </Disclosure>
  </header>
}

function ChatButton({ className }:{
  className?: string
}) {
  const { openDraw, draw, close } = useSidebarDraw()
  const isActive = draw == 'chat';
  const { flags } = useFeatureFlags();
  const user = useUserInformation();

  if (!flags.FLAG_AI_CHAT_ENABLED && !user.isLoggedIn) {
    return <></>
  }

  function toggle() {
    draw == 'chat' ? close() : openDraw('chat')
  }

  if (draw !== null && draw !== 'search') {
    return <></>
  }

  return <button 
    aria-label="Chat" title="Chat" 
    className={classNames(
      className, 
      'border w-fit whitespace-nowrap border-white rounded-full p-3 hover:bg-navy-600 hover:text-white transition-all duration-300', 
      isActive ? 'text-white bg-navy-600' : ''
    )} 
    onClick={toggle}
  >
    <SparklesIcon className="w-[24px]" />
  </button>
}

function SearchButton({ className }: {
  className?: string
}) {
  const { products } = useProducts()
  const pathname = usePathname()
  const { isOpen, draw, openDraw, close, current, search } = useSearch()

  const isVisible = isOpen && draw == 'search';

  const toggleSearch = () => {
    if (!isVisible && !current?.query && (!current?.filters?.products || current?.filters?.products.length == 0)) {
      const inContextProducts = products.filter(p => pathname.startsWith(p.path.alias))
      if (inContextProducts.length) {
        const productName = inContextProducts.reduce((a, b) => a.path.alias.length > b.path.alias.length ? a : b).name;
        search('', { products: [ productName ]})
      }
      return openDraw('search')
    }
    else {
      return isVisible ? close() : openDraw('search')
    }
  }

  if (draw !== null && draw !== 'chat') {
    return <></>
  }

  return <button 
    aria-label="Search" title="Search" 
    className={classNames(className, 'border w-fit whitespace-nowrap border-white rounded-full p-3 hover:bg-navy-600 hover:text-white transition-all duration-300', isVisible ? 'text-white bg-navy-600' : '')} 
    onClick={toggleSearch}
  >
    <MagnifyingGlassIcon className="w-[24px]" />
  </button>
}

function DropDownMenu({label, items, href}: {
  label: string
  items: DrupalMenuLinkContent[]
  href?: string
}) {
  return <Menu as="div" className="relative">
          <Menu.Button 
            className={({open}) => classNames(
              "flex items-center p-3 rounded-lg truncate",
              "hover:bg-gray-400",
              "focus:border-navy-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75",
              open ? 'bg-gray-400' : 'border-transparent'
            )}>
            {label}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={classNames(
            "xl:absolute xl:left-0 xllg:w-56 xl:ps-1 xl:origin-top-right xl:shadow-xl",
            "p-1 ps-4 divide-y divide-gray-100 rounded-b-md bg-white focus:outline-none z-30"
            )}>
            <div className="px-1 py-1">
              {items.map(link => (
                <Menu.Item key={link.id}>
                {({ active }) => (
                  <Link
                  href={link.url}
                  className={`${
                    active ? 'bg-gray-400' : ''
                    } text-gray-900 group flex w-full items-center rounded-md px-2 py-2`}
                    >
                    {link.title}
                  </Link>
                )}
              </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
}

