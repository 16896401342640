'use client'
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useAssistant as AIuseAssistant } from "ai/react";

type VercelAIAssistantReturnType = ReturnType<typeof AIuseAssistant>;
const AssistantContext = createContext<VercelAIAssistantReturnType & {
  retry: () => void
}>(null!)

export const useAssistant = () => useContext(AssistantContext);

export const AssistantProvider = (props: Partial<ReturnType<typeof AIuseAssistant>> & {
  children: ReactNode
}) => {
  const [threadId, setThreadId] = useState<string>();
  const assistant = AIuseAssistant({
    api: (process.env.NEXTAUTH_URL ?? '') + '/api/assistant',
    threadId
  })
  const assistantThreadId = assistant.threadId

  useEffect(() => {
    if (assistantThreadId && threadId !== assistantThreadId) {
      setThreadId(assistantThreadId)
    }
  }, [assistantThreadId, threadId])

  function retry() {
    const messages = assistant.messages
    const lastMessage = messages.pop()
    if (lastMessage) {
      assistant.setThreadId(undefined)
      assistant.setMessages(messages)
      assistant.append(lastMessage)
    }
  }

  return <AssistantContext.Provider value={{...assistant, retry}}>
    {props.children}
  </AssistantContext.Provider>
}