import { ForwardRefExoticComponent, ReactNode, RefAttributes, SVGProps } from "react";
import { DrawInterface } from "./SidebarDraw.client";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { Input } from "../chat/Input";
import { Disclaimer } from "../chat/Disclaimer";
import { Messages } from "../chat/Messages.client";
import { DrawOptions } from "@/contexts/SidebarDrawProvider";
import { LoginButton } from "./buttons.client";
import { AiGuidelinesAgreement } from "../chat/AiGuidelinesAgreement.client";

export class ChatDraw implements DrawInterface {
  public readonly type: DrawOptions = 'chat';
  public readonly Icon: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, "ref"> & { title?: string; titleId?: string; } & RefAttributes<SVGSVGElement>> = SparklesIcon;
  public readonly title: string = 'Acquia Copilot';

  public readonly aiGuidelinesConsent;
  public readonly isLoggedIn;

  constructor({ isLoggedIn, aiGuidelinesConsent }: {
    isLoggedIn: boolean
    aiGuidelinesConsent: boolean
  }) {
    this.isLoggedIn = isLoggedIn
    this.aiGuidelinesConsent = aiGuidelinesConsent
  }

  public get children():ReactNode {
    if (!this.isLoggedIn) {
      return <LoginButton title={this.title} />
    }
    if (!this.aiGuidelinesConsent) {
      return <AiGuidelinesAgreement />;
    }
    return <Messages />
  }

  public get footer():ReactNode {
    if (!this.isLoggedIn || !this.aiGuidelinesConsent) {
      return <></>
    }
    return <>
      <Input />
      <Disclaimer />
    </>
  }
}