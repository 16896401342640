'use client';

import { Body } from '@/components/wysiwyg/Body';
import { useAssistant } from '@/contexts/AssistantProvider';
import classNames from 'classnames';
import { parse } from 'marked';
import './loading.css';
import HugSvg from '@/assets/hug.svg';
import { UseAssistantHelpers } from '@ai-sdk/react';
import { DrupalReference } from '@/lib/search/openai-server-actions.server';
import Link from 'next/link';
import { Suggestions } from './Suggestions';
import { ReactNode, useId, useRef, useState } from 'react';
import { SupportHelp } from './SupportHelp.client';
import { Info } from '@/components/widget/info';
import { useProducts } from '@/contexts/ProductsProvider';
import { ProductLogo } from '@/components/assets/ProductLogo';
import { ProductLogoMachineNames, ProductLogoNames } from '@/types';
import { ArrowPathIcon, ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { AssistantStatus } from 'ai';
import { sendGTMEvent } from '@next/third-parties/google';

export const Messages = () => {
  const assistant = useAssistant();
  const id = useId();
  
  const { messages, status } = assistant;

  if (status == 'in_progress') {
    document.getElementById(id)?.scrollIntoView({behavior: "smooth"})
  }

  if (!messages.length) {
    return <GettingStarted />
  }

  const lastMessageType = messages[messages.length - 1].role

  return <div className='flex flex-col gap-4 p-4' role='log' aria-live='polite' aria-relevant="additions" aria-label='Acquia Copilot'>
    {messages.map((m, i) => <ChatMessage key={m.id} {...m} status={status} isLast={i == (messages.length - 1) } />)}
    {status == 'in_progress' && 
      <MessageRow orientation='left'>
        <div className='max-w-lg mr-auto'>
          <div id={id} className="mr-auto loader w-6" />
          { lastMessageType == 'user' && (
            <>
              <span className='sr-only'>Please wait...</span>
              <p className='text-sm italic text-gray-600'>This may take a few moments to consult our knowledge bases and construct a response.</p>
            </>
          )}
        </div>
      </MessageRow>
    }
    {messages.length > 1 && status != 'in_progress' &&  (
      <MessageRow orientation='left'>
        <SupportHelp />
      </MessageRow>
    )}
  </div>
}

function GettingStarted() {
  return <div className='text-center'>
    <HugSvg className="w-24 mx-auto mt-12" />
    <h3 className='font-medium text-xl mt-4'>How can I help today?</h3>
    <h4 className='font-medium mt-6'>Suggestions</h4>
    <Suggestions />
  </div>
}

export function MessageRow({ orientation, children }: {
  children: ReactNode
  orientation: 'left' | 'right'
}) {
  return <div className={classNames('flex', orientation == 'left' ? '' : 'flex-row-reverse')}>
    {children}
    <span className='w-8' />
  </div>
}

function ChatMessage(message: UseAssistantHelpers['messages'][number] & {
  status: AssistantStatus
  isLast: boolean
}) {
  switch (message.role) {
    case 'assistant':
        return <MessageRow orientation='left'>
            <AssistantChatMessage {...message} />
          </MessageRow>
    case 'data':
        return <MessageRow orientation='left'>
            <DataChatMessage {...message} />
          </MessageRow>
    case 'user':
        return <MessageRow orientation='right'>
            <UserChatMessage {...message} />
          </MessageRow>
  }
}

function UserChatMessage({content, status, isLast}: UseAssistantHelpers['messages'][number] & {
  status: AssistantStatus
  isLast: boolean
}) {
  function UserMessageOptions({ children }: {
    children:ReactNode
  }) {
    const [retried, setRetry] = useState<boolean>()
    const { retry } = useAssistant();

    if (status != 'awaiting_message' || !isLast ) {
      return <>{children}</>
    }

    function doRetry() {
      retry()
      setRetry(true)
    }
  
    const buttonClass = 'p-2 rounded-lg transition-all duration-300 hover:bg-gray-400'
  
    return <div className='flex flex-col gap-2 group relative'>
      {children}
      <div className='flex ml-auto group-hover:flex bg-white rounded-lg border border-gray-600 p-1 items-center gap-2 text-navy-600 shadow w-fit'>
        <button title="Retry" onClick={() => doRetry()} className={classNames(buttonClass, 'flex items-center gap-2')}>
          <><ArrowPathIcon className={classNames('w-5', retried ? 'animate-spin' : '')} title="Retry" aria-hidden="true" /><span className=''>Retry</span></>
        </button>
      </div>
    </div>
  }
  return <UserMessageOptions>
    <div 
      aria-label='User message'
      className={classNames(
        'ml-auto bg-blue-400 text-navy-600',
        "p-4 rounded-lg w-fit"
      )}
      >
    <p className='sr-only'>User: </p>
    {content}
  </div>
  </UserMessageOptions>
}


function AssistantChatMessage({content, status}: UseAssistantHelpers['messages'][number] & {
  status: AssistantStatus
}) {

  const ref = useRef<HTMLDivElement>(null);

  function extractCitations(content: string): string {
    return content.replaceAll(/【\d+:\d+†.*】/g, '');
  }

  function AssistantMessageOptions({ children }: {
    children:ReactNode
  }) {

    const [copied, setCopied] = useState(false)
    const [liked, setLiked] = useState<boolean>()

    const voted = liked !== undefined;
    
    if (status != 'awaiting_message') {
      return <>{children}</>
    }

    function copyToClipboard() {
      navigator.clipboard.write([new ClipboardItem({
        ["text/html"]: new Blob([ref.current?.innerHTML ?? ''], { type: 'text/html'})
      })])
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 3000)
      })
      .catch(err => {
        setCopied(false)
        console.error(err);
      });
  }

  function like(vote?: boolean) {
    setLiked(vote)
    if (vote !== undefined) {
      sendGTMEvent({
        event: vote ? 'chatLike' : 'chatDislike',
        value: true
      })
    }
  }

  const buttonClass = 'p-2 rounded-lg transition-all duration-300 hover:bg-gray-400'

    return <div className='flex flex-col gap-2 group relative'>
      {children}
      <div className='flex group-hover:flex bg-white rounded-lg border border-gray-600 p-1 items-center gap-2 text-navy-600 shadow w-fit'>
        <button onClick={() => copyToClipboard()} className={classNames(buttonClass)}>
          {copied ? (
            <><ClipboardDocumentCheckIcon className='w-5' aria-hidden="true" /><span className='sr-only'>copied!</span></>
          ) : (
            <><ClipboardDocumentListIcon className='w-5' aria-hidden="true" /><span className='sr-only'>copy</span></>
          )}
        </button>
        <span className='border-s-2 border-gray-600'>&nbsp;</span>
        <button 
          className={classNames(buttonClass, voted && liked ? 'text-teal-600' : 'disabled:text-gray-800 disabled-hover:border-transparent')} 
          disabled={voted && !liked} 
          onClick={() => like(voted ? undefined : true)}
        >
          <HandThumbUpIcon className='w-5' aria-hidden="true" /><span className='sr-only'>Like</span>
        </button>
        <button 
          className={classNames(buttonClass, voted && !liked ? 'text-pink-600' : 'disabled:text-gray-800 disabled-hover:border-transparent')} 
          disabled={voted && liked} 
          onClick={() => like(voted ? undefined : false)}
        >
          <HandThumbDownIcon className='w-5' aria-hidden="true" /><span className='sr-only'>Dislike</span>
        </button>
      </div>
    </div>
  }

  return <AssistantMessageOptions>
        <div 
          className={classNames(
            'bg-gray-400 text-black mr-auto',
            "p-4 rounded-lg w-fit"
          )}
        >
        <p className='sr-only'>Acquia Copilot: </p>
        <div ref={ref}>
          <Body value={parse(extractCitations(content), {async: false}) as string} />
        </div>
      </div>
    </AssistantMessageOptions>
}

type DataDrupalLinks = {
  drupal_links: Record<string, DrupalReference>
}

function DataChatMessage({data}: UseAssistantHelpers['messages'][number]) {

  if (!data) {
    return;
  }

  const citations = data as unknown as DataDrupalLinks
  const links = Object.keys(citations.drupal_links).map(key => citations.drupal_links[key]);

  if (!links.length) {
    console.warn("No links were found in Drupal for the citations. This means the data is likely to be out dated.")
    return;
  }
  return <div 
  className={classNames(
    'bg-gray-300 text-orange-800 mr-auto',
    "p-4 rounded-lg w-fit",
    "flex flex-col gap-2",
  )}>
    <div className='flex items-center gap-1'>
      <h2 className='font-medium'>See also</h2>
      <Info pointer='left' className='w-48 text-xs -top-11 left-5'>Data from these resources was used to construct the above reponse.</Info>
    </div>
    <ul className='pl-4 flex flex-col gap-1'>
  {links.map(link => (
    <li key={link.id}><Link className='hover:text-blue-600 hover:underline flex items-center gap-2' href={link.path[0].alias}><FromProductLogo {...link} />{link.label}</Link></li>
  ))}
  </ul>
</div>
}

function FromProductLogo(props : DrupalReference) {
  const { products } = useProducts()

  const logos = props.products?.map( ({ uuid }) => {
    const options = products.filter(p => p.id == uuid)
    if (!options.length) {
      return null
    }
    return options[0].field_product_logo;
  })
  .filter(l => l) as Array<ProductLogoMachineNames | ProductLogoNames>

  if (!logos?.length) {
    return <></>
  }

  return <>
    {logos.map(logo => <ProductLogo key={logo} className='w-4' name={logo} />)}
  </>
}