import { useContentFromRoute } from "@/contexts/ContentRouteProvider"
import { useProducts } from "@/contexts/ProductsProvider"
import { useSearch } from "@/contexts/SearchProvider"
import { DrupalTaxonomyTermProduct } from "@/types"
import { Fragment } from "react"

export const SearchQuestions = () => {
  const { products } = useProducts()
  const { resource } = useContentFromRoute()
  const { current, search } = useSearch()

  let relevantProducts = products.filter(p => current?.filters?.products?.includes(p.name))
  relevantProducts.push(...(resource?.field_products ?? (resource?.field_product ? [resource.field_product] : [])))
  // Make unique.
  relevantProducts = relevantProducts.reduce((r, c) => {
    if (!r.map(p => p.id).includes(c.id)) {
      r.push(c)
    }
    return r
  }, [] as DrupalTaxonomyTermProduct[])

  const common_questions = relevantProducts?.filter(product => product.field_common_search_questions?.length);
  
  return <div className="bg-teal-300 rounded-lg p-6 my-4">
    {common_questions?.map(product => <Fragment key={product.id}>
      <h3>{product.name} common questions</h3>
      
      <ul className="list-disc flex flex-col space-y-2">
      {product.field_common_search_questions?.map((question, i) => 
        <li key={i} className="ml-8"><button className="underline text-blue-600 text-left" onClick={(e) => search(question, { products: [product.name] }, 'question')}>{question}</button></li>
      )}
      </ul>
    </Fragment>)}
    {(common_questions === undefined || common_questions.length < 1) && (<>
      <h3>Common questions</h3>
      <ul className="list-disc flex flex-col space-y-2">
        <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => search("How do I upload an SSL certificate?")}>How do I upload an SSL certificate?</button></li>
        <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => search("How do I get PHP error logs?")}>How do I get PHP error logs?</button></li>
        <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => search("Can I run a task on a schedule?")}>Can I run a task on a schedule?</button></li>
        <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => search("What security features are available?")}>What security features are available?</button></li>
      </ul>
    </>)}
  </div>
}