'use client';

import { useAssistant } from "@/contexts/AssistantProvider";
import { searchParamsSearch } from "@/lib/searchParams";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useQueryStates } from "nuqs";
import { useEffect, useRef } from "react";

export const Input = () => {
  const { status, input, handleInputChange, submitMessage, setInput, messages } = useAssistant();
  const [ { searchQuery }] = useQueryStates(searchParamsSearch)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const maxLength = 280

  const defaultInputFromSearchQuery = !input && searchQuery && messages.length === 0;

  useEffect(() => {
    if (defaultInputFromSearchQuery) {
      setInput(searchQuery)
    }
  }, [defaultInputFromSearchQuery, searchQuery, setInput])

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '0'; // Reset height to auto
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
      textarea.rows = Math.round(textarea.scrollHeight / 32);
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [input]);

  useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();  // Prevent newline from being added
      submitMessage();
    }
  };

  const inputLength = textareaRef.current?.value.length ?? 0
  const maxLimitHit = inputLength >= maxLength
  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const maxLimitHit = event.target.value.length >= maxLength
    if (maxLimitHit) {
      event.stopPropagation()
      setInput(event.target.value.substring(0, maxLength) ?? '')
      return
    }
    handleInputChange(event)
  }

  return <form onSubmit={submitMessage} className="pt-4">
    <p title="Character limit" className={classNames(
      'text-xs text-right m-0',
      maxLimitHit ? 'text-orange-600' : 'text-gray-800'
    )}>{Math.min(inputLength, maxLength)}/{maxLength}<span className="sr-only"> characters</span></p>
    <span className="flex border border-gray-600 rounded bg-white">
  <textarea
    ref={textareaRef}
    id="chat-input"
    className="w-full p-2 max-h-min resize-none placeholder:text-gray-700 bg-transparent disabled:bg-gray-400 disabled:text-gray-600"
    disabled={status !== 'awaiting_message'}
    value={input}
    placeholder={status == 'in_progress' ? "Awaiting response..." : "Message Copilot"}
    onKeyDown={handleKeyDown}
    onChange={onInputChange}
    />
  <button
    className="disabled:cursor-not-allowed"
    disabled={ status !== 'awaiting_message' || input.length === 0 }
    title="Send"
    type="submit"
  >
    <PaperAirplaneIcon className="w-4 mx-2" />
  </button>
    </span>
</form>
}