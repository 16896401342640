
'use client'
import { AssistantProvider } from "@/contexts/AssistantProvider";
import { ForwardRefExoticComponent, ReactNode, RefAttributes, SVGProps, useEffect, useState } from "react"
import { SearchDraw } from "./search";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { ChatDraw } from "./chat";
import classNames from "classnames";
import { useUserInformation } from "@/contexts/UserProvider";
import { useFeatureFlags } from "@/contexts/FeatureFlagsProvider";
import { useAppSelector } from "@/redux/hooks";
import { aiGuidelines } from "@/redux/features/consent";
import { DrawOptions, useSidebarDraw } from "@/contexts/SidebarDrawProvider";

export interface DrawInterface {
  Icon: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, "ref"> & {
    title?: string;
    titleId?: string;
} & RefAttributes<SVGSVGElement>>,
  type: DrawOptions,
  title: string,
  forceOpen?: () => boolean
  children: ReactNode,
  footer: ReactNode,
  close?: () => void 
}

export const SidebarDraw = () => {

  const { isOpen, openDraw, draw, close } = useSidebarDraw()
  const [ isShowing, setIsShowing ] = useState<boolean>( isOpen );

  const { isLoggedIn } = useUserInformation()
  const { flags: { FLAG_AI_CHAT_ENABLED } } = useFeatureFlags();
  const aiGuidelinesConsent = useAppSelector(aiGuidelines);

  const draws = new Map<DrawOptions, DrawInterface>();
  draws.set('search', new SearchDraw());
  draws.set('chat', new ChatDraw({
    isLoggedIn,
    aiGuidelinesConsent
  }));

  const drawInstance = draw ? draws.get(draw) : undefined;

  const forceOpen = drawInstance?.forceOpen ? drawInstance.forceOpen() : false;
  const drawType = drawInstance?.type;

  useEffect(() => {
    if (!isOpen && forceOpen && drawType) {
      openDraw(drawType)
    }
  }, [forceOpen, isOpen, drawType, openDraw])

  useEffect(() => {
    // Set a 600ms delay between isShowing and draw values. This ensures
    // the contents of the draw stay while the transition animation plays. 
    setTimeout(() => setIsShowing(isOpen), 600);
  }, [isShowing, isOpen])

  return <div
    className={classNames(
      "transition-all duration-300 ease-in min-h-screen w-screen",
      draw !== null ? 'xl:max-w-[34vw] lg:max-w-[50vw]' : 'max-w-0',
    )}
    >
    { drawInstance && <aside 
      id="sidebar-draw"
      role="region"
      aria-labelledby="drawTitle"
      className="bg-white fixed xl:max-w-[34vw] lg:max-w-[50vw] w-screen h-screen lg:shadow-lg flex flex-col justify-between">
      {/* 
        Here we are setting a static height (h-16). This is to allow children to offset
        sticky elements but a determinable about. See <SidebarSearch>.
      */}
      <div className="bg-navy-600 text-white p-4 flex items-center gap-2 sticky top-0 h-16 overflow-hidden z-20">
        <drawInstance.Icon className="w-8" role="none" />
        <h2 id="drawTitle">{ drawInstance.title }</h2>
        <span className="flex-grow" />
        <button role='button' aria-controls='sidebar-draw' aria-expanded="true" onClick={close} className="p-2 rounded hover:border-white border border-transparent">
          <XMarkIcon role="none" className="w-4" />
          <span className="sr-only">Close</span>
        </button>
      </div>
        <div className="flex-1 overflow-y-auto pb-24">
          { drawInstance.children }
        </div>
        { drawInstance.footer && (
          <footer className="sticky bottom-0 px-4 bg-gray-300">
          { drawInstance.footer }
        </footer>
        )}
      </aside>}
  </div>
}