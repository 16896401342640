import { createAppSlice } from "../createAppSlice"; 
import { type PayloadAction } from "@reduxjs/toolkit";
import { load, save } from "../utils";

export interface ConsentState {
  aiGuidelines: boolean
}

const initialState: ConsentState = {
  aiGuidelines: false
};

export const consent = createAppSlice({
  name: 'consent',
  initialState,
  reducers: (create) => ({
    ack: create.reducer(
      (state, action: PayloadAction<keyof ConsentState>) => {
        state[action.payload] = true
        save<ConsentState>('consent', state)
      }
    ),
    initialize: create.reducer(
      (state) => load<ConsentState>('consent')
    )
  }),
  selectors: {
    aiGuidelines: (state) => state.aiGuidelines
  }
})

export const {ack} = consent.actions
export const {aiGuidelines} = consent.selectors